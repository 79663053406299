import styles from "./MarqueeLine.module.scss";
import Marquee from "react-fast-marquee";

export default function MarqueeLine() {
    const marqueeItems = [
        "Исследования",
        "UX/UI дизайн",
        "Разработка",
        "Проектирование",
        "Тестирование",
        "Верстка",
        "Прототипирование",
        "Аналитика",
        "Веб-дизайн",
    ];
    const repeatedList = [
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
        ...marqueeItems,
    ];
    const marqueeItemsList = repeatedList.map((item, index) => {
        const checkOrder = [2, 3, 4, 5].includes(index % marqueeItems.length);
        return (
            <li key={index} className={styles.marqueeItem}>
                <div
                    className={checkOrder ? styles.smallPoint : styles.bigPoint}
                ></div>
                {item}
            </li>
        );
    });

    return (
        <div className={styles.marqueeWrapper}>
            <Marquee className={styles.marquee} autoFill={true} speed={200}>
                {marqueeItemsList}
            </Marquee>{" "}
        </div>
    );
}
