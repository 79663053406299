import styles from "./MobileMenu.module.scss";
import { Link, useLocation } from "react-router-dom";
import {
    handlePhoneClick,
    handleTelegramClick,
    handleEmailClick,
    handleInstagramClick,
} from "../../utils/contactHelpers";
import {trackEvent} from "../../utils/metrikaHelper";

/*
                    <Link to="/promo">
                        <button
                            onClick={() => {
                                trackEvent('reachGoal', 'menu-click');
                                onClose();
                            }}
                            className={
                                isSelected("/promo") ? styles.selected : ""
                            }
                        >
                            <span>Промо</span>
                        </button>
                    </Link>
 */

function MobileMenu({
    onClose,
    setDrawerOpened,
    setOrigin,
    setOpenedFromMenu,
}) {
    const location = useLocation();
    const isSelected = (path) => location.pathname === path;
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <nav className={styles.headerLeft}>
                    <Link to="/">
                        <button onClick={onClose}>
                            Codify
                            <svg
                                width="3"
                                height="3"
                                viewBox="0 0 3 3"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    fill="black"
                                />
                            </svg>
                            Software
                        </button>
                    </Link>
                </nav>
                <svg
                    className={styles.headerRight}
                    onClick={onClose}
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M26 2L2 26" stroke="black" strokeWidth="2" />
                    <path d="M2 2L26 26" stroke="black" strokeWidth="2" />
                </svg>
            </div>
            <div className={styles.content}>
                <nav className={styles.menu}>
                    <Link to="/about">
                        <button
                            onClick={() => {
                                trackEvent('reachGoal', 'menu-click');
                                onClose();
                            }}
                            className={
                                isSelected("/about") ? styles.selected : ""
                            }
                        >
                            <span>О нас</span>
                        </button>
                    </Link>

                    <Link to="/services">
                        <button
                            onClick={() => {
                                trackEvent('reachGoal', 'menu-click');
                                onClose();
                            }}
                            className={
                                isSelected("/services") ? styles.selected : ""
                            }
                        >
                            <span>Услуги</span>
                        </button>
                    </Link>

                    <Link to="/portfolio">
                        <button
                            onClick={() => {
                                trackEvent('reachGoal', 'menu-click');
                                onClose();
                            }}
                            className={
                                isSelected("/portfolio") ? styles.selected : ""
                            }
                        >
                            <span>Портфолио</span>
                        </button>
                    </Link>
                </nav>
                <div className={styles.positionBottom}>
                    <button
                        onClick={() => {
                            setOrigin("request");
                            setDrawerOpened(true);
                            setOpenedFromMenu(true);
                        }}
                        className={styles.orderButton}
                    >
                        Заказать проект
                    </button>
                    <footer className={styles.footer}>
                        <ul>
                            <li onClick={handlePhoneClick}>
                                +7 (911) 469-85-29
                            </li>
                            <li onClick={handleTelegramClick}>@codify_software</li>
                            <li onClick={handleEmailClick}>
                                ceo@codify.software
                            </li>
                            <li
                                onClick={handleInstagramClick}
                                className={styles.handleInstagram}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                >
                                    <g clipPath="url(#clip0_1439_7894)">
                                        <path
                                            d="M6.5001 8.90001C7.13662 8.90001 7.74707 8.64715 8.19715 8.19706C8.64724 7.74698 8.9001 7.13653 8.9001 6.50001C8.9001 5.86349 8.64724 5.25304 8.19715 4.80295C7.74707 4.35286 7.13662 4.10001 6.5001 4.10001C5.86358 4.10001 5.25313 4.35286 4.80304 4.80295C4.35295 5.25304 4.1001 5.86349 4.1001 6.50001C4.1001 7.13653 4.35295 7.74698 4.80304 8.19706C5.25313 8.64715 5.86358 8.90001 6.5001 8.90001Z"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M1.1001 8.90001V4.10001C1.1001 3.30436 1.41617 2.54129 1.97878 1.97869C2.54139 1.41608 3.30445 1.10001 4.1001 1.10001H8.9001C9.69575 1.10001 10.4588 1.41608 11.0214 1.97869C11.584 2.54129 11.9001 3.30436 11.9001 4.10001V8.90001C11.9001 9.69566 11.584 10.4587 11.0214 11.0213C10.4588 11.5839 9.69575 11.9 8.9001 11.9H4.1001C3.30445 11.9 2.54139 11.5839 1.97878 11.0213C1.41617 10.4587 1.1001 9.69566 1.1001 8.90001Z" />
                                        <path
                                            d="M9.80029 3.20655L9.80679 3.1994"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1439_7894">
                                            <rect
                                                width="12"
                                                height="12"
                                                fill="white"
                                                transform="translate(0.5 0.5)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                                codify_software
                            </li>
                        </ul>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;
