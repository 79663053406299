import styles from "./Title.module.scss";

export default function Title({ setDrawerOpened }) {
    return (
        <div className={styles.homePage}>
            <h1 className={styles.homeTitle}>
                Дизайн и&nbsp;разработка интерфейсов
            </h1>
            <button
                className={styles.titleButton}
                onClick={() => setDrawerOpened(true)}
            >
                Заказать проект
            </button>
        </div>
    );
}
