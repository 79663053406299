import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

import './index.scss';
import App from './App';

const ymID = 96258686;
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(
        <BrowserRouter>
            <YMInitializer accounts={[ymID]} options={{ webvisor: true, clickmap:true, defer:true, trackLinks: true, accurateTrackBounce: true }} version="2" />
            <App />
        </BrowserRouter>,
        rootElement
    );
} else {
    render(
        <BrowserRouter>
            <YMInitializer accounts={[ymID]} options={{ webvisor: true, clickmap:true, defer:true, trackLinks: true, accurateTrackBounce: true }} version="2" />
            <App />
        </BrowserRouter>,
        rootElement
    );
}
