import { Link } from "react-router-dom";
import styles from "./HeaderPromo.module.scss";
import LogoButton from "./LogoButton";
import {trackEvent} from "../../utils/metrikaHelper";

export default function HeaderPromo() {
    return (
        <header data-anim="noAnim" className={styles.header}>
            <div className={styles.headerLeft}>
                <Link to="/">
                    <LogoButton />
                </Link>
            </div>
            <div className={styles.headerCenter}>
                <Link to="/">
                    <button data-anim="noAnim" onClick={() => trackEvent('reachGoal', 'menu-click')}>
                        <span>Перейти на сайт</span>
                    </button>
                </Link>
            </div>
        </header>
    );
}
