import styles from "./Tasks.module.scss";

export default function Tasks() {
    const taskNames = [
        "UX/UI",
        "Прототипирование",
        "Анимация",
        "Дизайн",
        "Аналитика",
        "Исследования",
        "Backend разработка",
        "Frontend разработка",
        "Поддержка",
        "Верстка",
        "Доработка",
        "Проектирование",
        "Веб-дизайн",
    ];
    const tasks = taskNames.map((task, index) => {
        return (
            <span key={index} className={styles.task}>
                {task}
            </span>
        );
    });

    return (
        <div className={styles.container}>
            <h2>Что мы можем?</h2>
            <div className={styles.taskContainer}>{tasks}</div>
        </div>
    );
}
