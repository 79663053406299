import React, { useCallback, useEffect, useLayoutEffect, useState, useRef } from 'react'
import './HOCAminMove.styles.scss'


const beetwen = 20
const isMobile = window.innerWidth < 767;
const HOCAminMove = ({ children, isShowAnim }) => {
    const allElements = useRef([])
    const block = useRef(null)
    const isElemDataAttr = (key, { x, y }) => {
        return document.elementFromPoint(x, y)?.dataset?.anim === key ||
            document.elementFromPoint(x + 10, y)?.dataset?.anim === key ||
            document.elementFromPoint(x - 10, y)?.dataset?.anim === key ||
            document.elementFromPoint(x + 10, y - 10)?.dataset?.anim === key ||
            document.elementFromPoint(x - 10, y + 10)?.dataset?.anim === key ||
            document.elementFromPoint(x, y - 10)?.dataset?.anim === key ||
            document.elementFromPoint(x, y + 10)?.dataset?.anim === key
    }
    const createElement = (x, y) => {
        if (block.current === null) return
        const element = document.createElement('img');
        element.setAttribute('src', require('../../assets/images/SquareAnimMove.png'))
        element.classList.add('.animElem')
        element.style.left = x + 'px';
        element.style.top = y + 'px';
        element.style.width = 50 + 'px';
        element.style.height = 50 + 'px';
        element.style.position = 'absolute';
        element.style.transform = 'translate(-30px, -30px)';
        element.style.zIndex = '-1';
        block.current.appendChild(element);

        const rect = element.getBoundingClientRect(); // получаем прямоугольник, описывающий элемент относительно окна
        const docElement = document.documentElement; // получаем корневой элемент документа
        const scrollLeft = window.pageXOffset || docElement.scrollLeft; // определяем смещение по горизонтали
        const scrollTop = window.pageYOffset || docElement.scrollTop; // определяем смещение по вертикали
        const xDoc = rect.left + scrollLeft; // координата X относительно документа
        const yDoc = rect.top + scrollTop; // координата Y относительно документа

        if (
            isElemDataAttr('noAnim', { x: xDoc, y: yDoc })
        ) {
            allElements.current.forEach(_ => _.remove())
            allElements.current = []
            element.remove()
            return
        }
        allElements.current.push(element)

    }
    const deleteElement = (delay = 300) => {
        setTimeout(() => {
            for (let i = 0; i < allElements.current.length - 1; i++) {
                const el = allElements.current[i]
                el.classList.add('removeElAnim')
                setTimeout(() => {
                    el.remove()
                }, delay)
            }
        }, delay)
    }
    const islastElement = (x, y) => {
        if (allElements.current.at(-1)) {
            const lastX = parseInt(allElements.current.at(-1).style.left);
            const lastY = parseInt(allElements.current.at(-1).style.top);
            const distance = Math.sqrt(Math.pow(x - lastX, 2) + Math.pow(y - lastY, 2));
            return (distance < beetwen)
        }
        return false
    }
    const renderElement = useCallback((e) => {
        if (block.current === null) return

        if (e?.target.dataset.anim === 'noAnim') {
            allElements.current.forEach(_ => _.remove())
            allElements.current = []
            return
        }

        const x = e.clientX - block.current.offsetLeft;
        const y = e.clientY - block.current.offsetTop;

        if (!islastElement(x, y)) {
            createElement(x, y)
            deleteElement(200)
        }
    }, [])
    const renderMobile = (isShow) => {
        if (block.current === null) return

        function getRandomPosition(element) {
            if (block.current === null) return [0, 0]
            const x = (block.current.offsetWidth - beetwen) - element.offsetWidth;
            const y = (block.current.offsetHeight - beetwen) - element.offsetHeight;
            const randomX = Math.floor(Math.random() * x);
            const randomY = Math.floor(Math.random() * y);
            return [randomX, randomY];
        }

        function showSquare() {
            if (block.current === null) return [0, 0]
            const square = document.createElement('img');
            square.setAttribute('src', require('../../assets/images/SquareAnimMove.png'))
            square.classList.add('element');
            square.style.width = 50 + 'px';
            square.style.height = 50 + 'px';
            square.style.position = 'absolute';
            square.style.zIndex = '-1';
            square.className = 'square';
            block.current.appendChild(square);
            const [x, y] = getRandomPosition(square);
            square.style.left = x + 'px';
            square.style.top = y + 'px';


            const rect = square.getBoundingClientRect(); // получаем прямоугольник, описывающий элемент относительно окна
            const docElement = document.documentElement; // получаем корневой элемент документа
            const scrollLeft = window.pageXOffset || docElement.scrollLeft; // определяем смещение по горизонтали
            const scrollTop = window.pageYOffset || docElement.scrollTop; // определяем смещение по вертикали
            const xDoc = rect.left + scrollLeft; // координата X относительно документа
            const yDoc = rect.top + scrollTop; // координата Y относительно документа
            if (
                isElemDataAttr('noAnim', { x: xDoc, y: yDoc })
            ) {
                allElements.current.forEach(_ => _.remove())
                allElements.current = []
                square.remove()
                return
            }
            if (!isShow) {
                block.current.removeChild(square);
                return
            }
            setTimeout(() => {
                square.classList.add('active');
            }, 100);
            setTimeout(() => {
                square.classList.remove('active');
                setTimeout(() => {
                    if (block.current === null) return
                    block.current.removeChild(square);
                }, 500);
            }, 2000);
        }
        showSquare();
    }

    useEffect(() => {
        if (isMobile) {
            const intervalId = setInterval(() => {
                renderMobile(isShowAnim)
            }, 500);
            if (!isShowAnim) return document.querySelectorAll('.square').forEach(_ => _.style.display = 'none');
            return () => clearInterval(intervalId);
        }
    }, [isShowAnim]);
    useEffect(() => {
        if (!isShowAnim) {
            allElements.current.forEach(_ => _.remove())
            allElements.current = []
            block.current?.removeEventListener('mousemove', renderElement);
            return
        }
        const elements = document.querySelectorAll('[data-anim]');
        elements.forEach((e) => {
            e.style.position = 'relative'
            e.style.zIndex = '110'
        })
        if (block.current === null) return
        block.current.addEventListener('mousemove', renderElement);
    }, [isShowAnim])
    return (
        <div style={{ position: 'relative', overflow: 'hidden' }} ref={block}>
            {children}
        </div>
    )
}

export default HOCAminMove
