import ym from 'react-yandex-metrika';

export const trackEvent = (goal, target = '') => {
    const id = process.env.REACT_APP_METRIKA_ID;

    if (id) {
        ym(id, goal, target);
    } else {
        console.error('No metrika id');
    }
};
