import styles from "./Order.module.scss";

export default function Order({ promoDate }) {
    return (
        <div className={styles.orderWrapper}>
            <div className={styles.arrow}>
                <svg
                    viewBox="0 0 129 126"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M109.551 0H129V20.1494L51.0822 98.0672H125V126H0V0.301758H27.9329V81.6184L109.551 0Z"
                        fill="#C8EFDA"
                    />
                </svg>
            </div>
            <p>
                Оставьте заявку&nbsp;до {promoDate}, чтобы получить скидку
                15%&nbsp;на любую услугу.
            </p>
        </div>
    );
}
