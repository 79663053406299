import { Link, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
import { trackEvent } from '../../utils/metrikaHelper';

/*
                <Link to="/promo">
                    <button
                        data-anim="noAnim"
                        className={isSelected("/promo") ? styles.selected : ""}
                        onClick={() => trackEvent('reachGoal', 'menu-click')}
                    >
                        <span>Промо</span>
                    </button>
                </Link>
 */

function Header() {
    const location = useLocation();
    const isSelected = (path) => location.pathname === path;

    return (
        <header data-anim="noAnim" className={styles.header}>
            <nav className={styles.headerLeft}>
                <Link to="/">
                    <button onClick={() => trackEvent('reachGoal', 'menu-click')}>
                        Codify
                        <svg
                            width="3"
                            height="3"
                            viewBox="0 0 3 3"
                            fill="none"
                            xmlns="https://www.w3.org/2000/svg"
                        >
                            <circle cx="1.5" cy="1.5" r="1.5" fill="black" />
                        </svg>
                        Software
                    </button>
                </Link>
            </nav>

            <nav className={styles.headerCenter}>
                <Link to="/about">
                    <button
                        data-anim="noAnim"
                        className={isSelected("/about") ? styles.selected : ""}
                        onClick={() => trackEvent('reachGoal', 'menu-click')}
                    >
                        <span>О нас</span>
                    </button>
                </Link>

                <Link to="/services">
                    <button
                        data-anim="noAnim"
                        className={
                            isSelected("/services") ? styles.selected : ""
                        }
                        onClick={() => trackEvent('reachGoal', 'menu-click')}
                    >
                        <span>Услуги</span>
                    </button>
                </Link>

                <Link to="/portfolio">
                    <button
                        data-anim="noAnim"
                        className={
                            isSelected("/portfolio") ? styles.selected : ""
                        }
                        onClick={() => trackEvent('reachGoal', 'menu-click')}
                    >
                        <span>Портфолио</span>
                    </button>
                </Link>
            </nav>
        </header>
    );
}

export default Header;
