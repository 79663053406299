import AboutUs from "../../components/AboutUs/AboutUs";
import Contacts from "../../components/Contacts/Contacts";
import Title from "../../components/Title/Title";
import Order from "../../components/Order/Order";
import Request from "../../components/Request/Request";
import MarqueeLine from "../../components/MarqueeLine/MarqueeLine";
import Tasks from "../../components/Tasks/Tasks";
import styles from "./Promo.module.scss";
import { format, add, isPast } from "date-fns";
import { ru } from "date-fns/locale";
import ym from 'react-yandex-metrika';
import {useEffect} from "react";
import {Helmet} from "react-helmet";

export default function Promo({ setDrawerOpened }) {
    let promoDays = 5;
    let currentDate = new Date();
    let dateFromLocalStorage = Date.parse(localStorage.codifyPromoDate);

    if (
        !localStorage.codifyPromoDate ||
        isPast(add(dateFromLocalStorage, { days: promoDays }))
    ) {
        localStorage.codifyPromoDate = currentDate;
    } else {
        currentDate = dateFromLocalStorage;
    }
    let promoDate = format(add(currentDate, { days: promoDays }), "d MMMM", {
        locale: ru,
    });

    useEffect(() => {

        ym('hit', '/promo');

        return () => {};
    }, []);

    return (
        <>
            <Helmet>
                <title>Акция для новых клиентов | Codify Software</title>
                <meta
                    name="description"
                    content="Скидка 15% на любую услугу для новых клиентов. Backend, frontend разработка интернет магазинов, лендингов, сайтов и веб приложений любой сложности под ключ."
                />
                <meta
                    name="keywords"
                    content="скидка, разработка, интернет магазин, лендинг, сайт, под ключ"
                />
            </Helmet>
            <Title setDrawerOpened={setDrawerOpened} />
            <MarqueeLine />
            <AboutUs />
            <Tasks />
            <Order promoDate={promoDate} />
            <div className={styles.contacts}>
                <Contacts />
                <div className={styles.requestWrapper}>
                    <Request />
                </div>
            </div>
        </>
    );
}
