export const handlePhoneClick = () => {
    window.open("https://wa.me/+79114698529", "_blank");
};

export const handleTelegramClick = () => {
    window.open("https://t.me/codify_software", "_blank");
};

export const handleEmailClick = () => {
    window.location.href = "mailto:ceo@codify.software";
};

export const handleInstagramClick = () => {
    window.location.href = "https://www.instagram.com/codify_software";
};
