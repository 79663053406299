import styles from "./AboutUs.module.scss";
import { useState, useRef, useEffect } from "react";
import slideInStyles from "../../pages/About/slideInStyles.module.scss";

export default function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [part1Visible, setPart1Visible] = useState(false);
    const [part2Visible, setPart2Visible] = useState(false);
    const [part3Visible, setPart3Visible] = useState(false);
    const part1Ref = useRef(null);
    const part2Ref = useRef(null);
    const part3Ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (
                        entry.target === part1Ref.current &&
                        !part1Visible &&
                        entry.isIntersecting
                    ) {
                        setPart1Visible(true);
                    } else if (
                        entry.target === part2Ref.current &&
                        !part2Visible &&
                        entry.isIntersecting
                    ) {
                        setPart2Visible(true);
                    } else if (
                        entry.target === part3Ref.current &&
                        !part3Visible &&
                        entry.isIntersecting
                    ) {
                        setPart3Visible(true);
                    }
                });
            },
            { threshold: 0 }
        );

        observer.observe(part1Ref.current);
        observer.observe(part2Ref.current);
        observer.observe(part3Ref.current);

        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line
    }, []);
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.principlesTitle}>Чем мы занимаемся?</h2>
            <div className={styles.principles}>
                <div
                    ref={part1Ref}
                    className={`${styles.partOpacity0} ${styles.part} ${
                        part1Visible ? slideInStyles.fadeIn : ""
                    }`}
                >
                    <span>01</span>
                    <div>
                        <h3>Помогаем стартапам создать&nbsp;MVP</h3>
                        <p>
                            Берем на себя роль и обязанности команды,&nbsp;
                            <br />
                            проводим исследования, проектируем и&nbsp;
                            <br />
                            тестируем продукт.
                        </p>
                    </div>
                </div>

                <div
                    ref={part2Ref}
                    className={`${styles.partOpacity0} ${styles.part} ${
                        styles.part2
                    } ${part2Visible ? slideInStyles.fadeIn : ""}`}
                >
                    <span>02</span>
                    <div>
                        <h3>Увеличиваем конверсию на&nbsp;сайте</h3>
                        <p>
                            Ищем и изучаем слабые места на&nbsp;сайте и <br />
                            исправляем их, тем самым увеличивая прибыль&nbsp;
                            <br />
                            бизнеса.
                        </p>
                    </div>
                </div>

                <div
                    ref={part3Ref}
                    className={`${styles.partOpacity0} ${styles.part} ${
                        styles.part3
                    } ${part3Visible ? slideInStyles.fadeIn : ""}`}
                >
                    <div></div>
                    <div className={styles.part}>
                        <span>03</span>
                        <div>
                            <h3>Помогаем продуктовым командам</h3>
                            <p>
                                Берем на себя часть работы, когда у вас
                                высокая&nbsp;
                                <br />
                                загрузка. Проводим исследования и тестируем
                                гипотезы. <br />
                            </p>
                            <p>
                                <span>&nbsp;</span>
                            </p>
                            <p>И многими другими вещами.... </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
