import styles from "./Footer.module.scss";
import {
    handlePhoneClick,
    handleTelegramClick,
    handleEmailClick,
    handleInstagramClick,
} from "../../utils/contactHelpers";

function Footer() {
    return (
        <footer className={styles.footer}>
            <ul>
                <li onClick={handlePhoneClick}>+7 (911) 469-85-29</li>
                <li onClick={handleTelegramClick}>@codify_software</li>
                <li onClick={handleEmailClick}>ceo@codify.software</li>
                <li
                    onClick={handleInstagramClick}
                    className={styles.handleInstagram}
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1439_7901)">
                            <path
                                d="M10 14C11.0609 14 12.0783 13.5786 12.8284 12.8284C13.5786 12.0783 14 11.0609 14 10C14 8.93913 13.5786 7.92172 12.8284 7.17157C12.0783 6.42143 11.0609 6 10 6C8.93913 6 7.92172 6.42143 7.17157 7.17157C6.42143 7.92172 6 8.93913 6 10C6 11.0609 6.42143 12.0783 7.17157 12.8284C7.92172 13.5786 8.93913 14 10 14Z"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M1 14V6C1 4.67392 1.52678 3.40215 2.46447 2.46447C3.40215 1.52678 4.67392 1 6 1H14C15.3261 1 16.5979 1.52678 17.5355 2.46447C18.4732 3.40215 19 4.67392 19 6V14C19 15.3261 18.4732 16.5979 17.5355 17.5355C16.5979 18.4732 15.3261 19 14 19H6C4.67392 19 3.40215 18.4732 2.46447 17.5355C1.52678 16.5979 1 15.3261 1 14Z"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M15.5 4.51002L15.51 4.49902"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1439_7901">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    codify_software
                </li>
            </ul>
        </footer>
    );
}

export default Footer;
